import {Box, Typography, ThemeProvider, Container, Button} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {defaultTheme} from "./themes";

import {API, Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth/lib/types";

import "@aws-amplify/ui-react/styles.css";
import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import {GoogleIcon} from "./GoogleIcon";
import {useEffect, useState} from "react";
import {listNotes} from "./graphql/queries";
import {
  createNote as createNoteMutation,
  deleteNote as deleteNoteMutation,
} from "./graphql/mutations";

import {GraphQLResult} from "@aws-amplify/api";
import type {ListNotesQuery} from "./API";

const initialFormState = {name: "", description: ""};

const App = () => {
  const {user, signOut} = useAuthenticator((context) => [context.user]);

  const [notes, setNotes] = useState<
    Array<{
      id?: string;
      name: string;
      description?: string | null;
    } | null>
  >([]);
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    fetchNotes();
  }, []);

  async function fetchNotes() {
    const apiData = (await API.graphql({
      query: listNotes,
    })) as GraphQLResult<ListNotesQuery>;
    setNotes(apiData.data?.listNotes?.items || []);
  }

  async function createNote() {
    if (!formData.name || !formData.description) return;
    await API.graphql({
      query: createNoteMutation,
      variables: {input: formData},
    });
    setNotes([...notes, formData]);
    setFormData(initialFormState);
  }

  async function deleteNote({id}: {id?: string}) {
    const newNotesArray = notes.filter((note) => note?.id !== id);
    setNotes(newNotesArray);
    await API.graphql({query: deleteNoteMutation, variables: {input: {id}}});
  }

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <Box textAlign="center" p={2}>
            <Typography variant="h3" component="h1">
              Schedulemaster sync
            </Typography>
          </Box>
          <Box margin={2}>
            {user ? (
              <>
                <Typography>{user.attributes?.email}</Typography>
                <Button fullWidth onClick={() => signOut()}>
                  Log out
                </Button>
              </>
            ) : (
              <Button
                fullWidth
                onClick={() =>
                  Auth.federatedSignIn({
                    provider: CognitoHostedUIIdentityProvider.Google,
                  })
                }
                startIcon={<GoogleIcon />}
                variant="outlined"
              >
                Log in with Google
              </Button>
            )}
          </Box>

          <Box>
            <input
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              placeholder="Note name"
              value={formData.name}
            />
            <input
              onChange={(e) =>
                setFormData({...formData, description: e.target.value})
              }
              placeholder="Note description"
              value={formData.description}
            />
            <button onClick={createNote}>Create Note</button>
            <div style={{marginBottom: 30}}>
              {notes.map(
                (note) =>
                  note && (
                    <div key={note.id || note.name}>
                      <h2>{note.name}</h2>
                      <p>{note.description}</p>
                      <button onClick={() => deleteNote(note)}>
                        Delete note
                      </button>
                    </div>
                  ),
              )}
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

export default () => (
  <Authenticator.Provider>
    <App />
  </Authenticator.Provider>
);
