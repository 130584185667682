//import {createMuiTheme} from "@material-ui/core";
//import {blue, pink, purple, red, yellow} from "@material-ui/core/colors";

import {createTheme} from "@mui/material";
import {blue, pink, red} from "@mui/material/colors";

export const defaultTheme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h1: {
      fontSize: "2.71828rem", // e
    },
    h2: {
      fontSize: "2rem", // 2
    },
    h3: {
      fontSize: "1.61803rem", // golden ratio (a.k.a. World's worst constant)
    },
    h4: {
      fontSize: "1.41421rem", // square_root(2)
    },
    h5: {
      fontSize: "1.30357rem", // Conway's constant
    },
    h6: {
      fontSize: "1.25992rem", // cube_root(2)
    },
  },
});
