import {SvgIcon} from "@mui/material";
import {SvgIconProps} from "@mui/material";

export const GoogleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fillRule="evenodd" {...props}>
      <path
        d="M21.43 12.62C21.43 11.95 21.37 11.31 21.26 10.69H12.40V14.33H17.46C17.25 15.51 16.58 16.50 15.59 17.17V19.53H18.63C20.40 17.89 21.43 15.48 21.43 12.62Z"
        fill="#4285F4"
      />
      <path
        d="M12.40 21.81C14.94 21.81 17.07 20.96 18.63 19.53L15.59 17.17C14.75 17.73 13.67 18.07 12.40 18.07C9.95 18.07 7.88 16.41 7.14 14.19H4.00V16.63C5.55 19.70 8.73 21.81 12.40 21.81Z"
        fill="#34A853"
      />
      <path
        d="M7.14 14.19C6.95 13.63 6.85 13.02 6.85 12.40C6.85 11.78 6.95 11.18 7.14 10.62V8.18H4.00C3.36 9.45 3 10.89 3 12.40C3 13.92 3.36 15.36 4.00 16.63L7.14 14.19Z"
        fill="#FBBC05"
      />
      <path
        d="M12.40 6.74C13.78 6.74 15.02 7.21 16.00 8.15L18.69 5.45C17.07 3.93 14.94 3 12.40 3C8.73 3 5.55 5.11 4.00 8.18L7.14 10.62C7.88 8.39 9.95 6.74 12.40 6.74Z"
        fill="#EA4335"
      />
    </SvgIcon>
  );
};
